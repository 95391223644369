<template>
  <div>
    <Frame title="Colaboradores" :loading="loading">
      <Title
        title="Datos generales"
        description="Captura la información personal del colaborador tal como aparece en sus documentos oficiales."
      />
      <Layout>
        <Column size="4">
          <Field label="Nombre">
            <Input
              v-model="employeeInfo.firstName"
              :required="true"
              text-transform="uppercase"
              :rules="'required'"
            />
          </Field>
        </Column>
        <Column size="4">
          <Field label="Apellido paterno">
            <Input
              v-model="employeeInfo.lastName1"
              :required="true"
              text-transform="uppercase"
              :rules="'required'"
            />
          </Field>
        </Column>
        <Column size="4">
          <Field label="Apellido materno">
            <Input v-model="employeeInfo.lastName2" text-transform="uppercase" />
          </Field>
        </Column>
        <Column size="4">
          <Field label="RFC">
            <Input
              v-model="employeeInfo.rfc"
              :required="true"
              text-transform="uppercase"
              :rules="{
                required: true,
                regex:
                  /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
              }"
            />
          </Field>
        </Column>
        <Column size="4">
          <Field label="CURP">
            <Input
              v-model="employeeInfo.curp"
              :required="true"
              text-transform="uppercase"
              :rules="{
                required: true,
                regex:
                  /^[A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/,
              }"
            />
          </Field>
        </Column>
        <Column size="4">
          <Field label="NSS">
            <Input
              v-model="employeeInfo.nss"
              :required="true"
              text-transform="uppercase"
              :rules="'required'"
            />
          </Field>
        </Column>
        <Column size="4">
          <Field label="Correo personal">
            <Input
              v-model="employeeInfo.personalEmail"
              :required="true"
              :rules="'required|email'"
            />
          </Field>
        </Column>
        <Column size="4">
          <Field label="Correo empresarial">
            <Input v-model="employeeInfo.enterpriseEmail" :rules="'email'" />
          </Field>
        </Column>
      </Layout>
      <hr />
      <Layout>
        <Column align="right">
          <Button color="terciary-outlined" @click="$parent.close">Cancelar</Button>
          <Button color="secondary" @click="saveEmployee">Guardar</Button>
        </Column>
      </Layout>
    </Frame>
  </div>
</template>

<script>
export default {
  name: 'EmployeeForm',
  props: {
    employee: Object,
  },
  mounted() {
    const employeeInfo = { ...this.employee };
    this.employeeInfo.pkTimEmployee = employeeInfo.pkTimEmployee;
    this.employeeInfo.firstName = employeeInfo.employeeName;
    this.employeeInfo.lastName1 = employeeInfo.employeeLastName;
    this.employeeInfo.lastName2 = employeeInfo.employeeSecondLastName;
    this.employeeInfo.rfc = employeeInfo.rfc;
    this.employeeInfo.curp = employeeInfo.curp;
    this.employeeInfo.nss = employeeInfo.nss;
    this.employeeInfo.personalEmail = employeeInfo.personalEmail;
    this.employeeInfo.enterpriseEmail = employeeInfo.enterpriseEmail;
  },
  data() {
    return {
      loading: false,
      employeeInfo: {
        pkTimEmployee: '',
        firstName: '',
        lastName1: '',
        lastName2: '',
        rfc: '',
        curp: '',
        nss: '',
        personalEmail: '',
        enterpriseEmail: '',
      },
    };
  },
  methods: {
    async saveEmployee() {
      const validate = await this.isValid();

      if (!validate) {
        return;
      }
      this.loading = true;
      this.save('employee', this.employeeInfo, 'stamp', 'pkTimEmployee')
        .then((data) => {
          if (data) {
            this.$root.$emit('reloadInfoEmployee');
            this.$parent.close();
            this.loading = false;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<template>
  <Frame :loading="loading">
    <Layout class="filter-bar-stamps">
      <Column size="3">
        <Field label="Ejercicio">
          <MultiSelect :options="catalogs.CumulativeYears" v-model="query.CumulativeYears[0]" />
        </Field>
      </Column>
      <Column size="3">
        <Field label="Mes">
          <MultiSelect
            :options="catalogs.CumulativeMonths"
            :multiple="true"
            v-model="query.CumulativeMonths"
          />
        </Field>
      </Column>
      <Column size="3">
        <div class="filter-employee-stamps">
          <Button color="secondary" @click="getEmployeeStamps"> Buscar </Button>
        </div>
      </Column>
    </Layout>
    <Layout v-if="data.length === 0 && firstLoad">
      <Column size="4" />
      <Column size="4">
        <ActionCard
          image="/assets/empty-image.svg"
          text="No existen recibos de nómina para los criterios de búsqueda."
        />
      </Column>
    </Layout>
    <Grid
      :data="data"
      :actionConditional="actions"
      :showAdd="false"
      :paginatedByServer="true"
      :pageSize="pagination.PageSize"
      :totalItems="pagination.TotalRecords"
      @changePage="changePage"
      v-if="data.length !== 0"
    >
      <template v-slot="{ row }">
        <GridColumn title="Estatus">
          <PayrollReceiptStatusIcon :receipt="row" />
        </GridColumn>
        <GridColumn title="Periodo">
          {{ row.idPaymentPeriod }}
        </GridColumn>
        <GridColumn title="No. de cálculo">
          {{ row.calculationNumber }}
        </GridColumn>
        <GridColumn title="Fecha de pago">
          {{ row.paymentDate }}
        </GridColumn>
        <GridColumn title="Periodicidad">
          {{ row.paymentPeriodicity }}
        </GridColumn>
        <GridColumn title="Percepciones">
          {{ row.perc }}
        </GridColumn>
        <GridColumn title="Deducciones">
          {{ row.deduc }}
        </GridColumn>
        <GridColumn title="Otros pagos">
          {{ row.otherPay }}
        </GridColumn>
      </template>
    </Grid>
  </Frame>
</template>

<script>
import PayrollReceiptStatusIcon from '../payrollReceipts/PayrollReceiptStatusIcon.vue';

export default {
  name: 'EmployeeStamps',
  props: {
    employeeInformation: Object,
  },
  components: {
    PayrollReceiptStatusIcon,
  },
  data() {
    return {
      data: [],
      loading: false,
      firstLoad: false,
      columns: [
        {
          title: 'Periodo',
          field: 'idPaymentPeriod',
        },
        {
          title: 'Número de pago',
          field: 'calculationNumber',
        },
        {
          title: 'Fecha de pago',
          field: 'paymentDate',
        },
        {
          title: 'Periodicidad',
          field: 'paymentPeriodicity',
        },
        {
          title: 'Percepciones',
          field: 'perc',
        },
        {
          title: 'Deducciones',
          field: 'deduc',
        },
        {
          title: 'Otros pagos',
          field: 'otherPay',
        },
        {
          title: 'Estatus',
          field: 'cfdiStatusCode',
          template: this.templateStatus,
        },
      ],
      query: {
        PKTimEmployee: 0,
        CumulativeYears: [new Date().getFullYear()],
        CumulativeMonths: [new Date().getMonth() + 1],
      },
      pagination: {
        PageNumber: 1,
        PageSize: 50,
        TotalRecords: null,
        TotalPages: null,
      },
      catalogs: {
        CumulativeYears: [],
        CumulativeMonths: [
          {
            id: '',
            label: 'Todos',
            children: [],
          },
        ],
      },
      months: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
    };
  },
  mounted() {
    this.query.PKTimEmployee = this.employeeInformation.pkTimEmployee;
    this.getEmployeeStamps();
    this.setCatalogs();
  },
  methods: {
    setCatalogs() {
      const currentYear = new Date().getFullYear();
      for (let index = currentYear - 5; index < currentYear + 2; index++) {
        this.catalogs.CumulativeYears.push({ id: index, label: index });
      }
      this.catalogs.CumulativeMonths[0].children = this.months.map((x, index) => {
        return {
          id: index + 1,
          label: x,
        };
      });
    },
    templateStatus(item) {
      const status = [
        {
          code: 'STAMPED',
          text: 'Timbrado',
          color: 'is-success',
        },
        {
          code: 'CANCELLED',
          text: 'Cancelado',
          color: 'is-quinary',
        },
        {
          code: 'FAILED',
          text: 'Fallido',
          color: 'is-color2',
        },
        {
          code: 'INPROC',
          text: 'En Proceso',
          color: 'is-terciary-outlined',
        },
        {
          code: 'PENDING',
          text: 'Pendiente',
          color: 'is-terciary',
        },
        {
          code: 'CANCELPROCESS',
          text: 'Cancelado en Proceso',
          color: 'is-terciary-outlined',
        },
      ];
      //
      const statusFinded = status.find((x) => x.code === item.cfdiStatusCode);
      return `<span class="pw-tag ${statusFinded.color}">${statusFinded.text}</span>`;
    },
    actions(item) {
      const options = [
        {
          text: 'Descargar PDF',
          callback: this.downloadPDF,
        },
        {
          text: 'Descargar XML',
          callback: this.downloadXML,
        },
        {
          text: 'Descargar A. Cancelación',
          callback: this.downloadCancelation,
        },
        {
          text: 'Descargar PDF y XML',
          callback: (payroll) => [this.downloadPDF(payroll), this.downloadXML(payroll)],
        },
      ];
      if (item.cfdiStatusCode !== 'CANCELLED') {
        options.splice(2, 1);
      }

      if (this.$can('DownloadPayroll', 'STM')) return options;
      return [];
    },
    getEmployeeStamps() {
      this.loading = true;

      const query = [
        `PageNumber=${this.pagination.PageNumber}`,
        `PageSize=${this.pagination.PageSize}`,
      ].join('&');
      const options = {};
      Object.keys(this.query).forEach((x) => {
        if (x !== 'PKTimEmployee') {
          options[x] = this.query[x].filter((y) => y !== '');
        } else {
          options[x] = this.query[x];
        }
      });
      this.$post(`employee/receipts?${query}`, options, 'stamp')
        .then(({ data, headers }) => {
          const pagination = headers['x-pagination'];
          this.firstLoad = true;
          if (data.length !== 0) {
            this.pagination = JSON.parse(pagination);
          }
          this.data = data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.setNotification('Ocurrio un error', 'danger');
        });
    },
    changePage(page) {
      this.pagination.PageNumber = page;
      this.getEmployeeStamps();
    },
    downLoadFile(DocumentType, PayrollEmployee, isAcuse = false) {
      let query;
      if (!isAcuse) {
        query = {
          FileType: DocumentType,
          PayrollEmployee: PayrollEmployee,
        };
      } else {
        query = {
          PayrollEmployeeID: PayrollEmployee,
        };
      }

      const fileTypes = {
        FileXML: 'data:application/xml;base64,',
        FilePDF: 'data:application/pdf;base64,',
      };
      const endpoint = !isAcuse ? 'storage/DownloadFile' : 'storage/AcuseCancellation';
      this.$stream(endpoint, query, 'stamp')
        .then(({ data }) => {
          const link = document.createElement('a');
          link.href = `${fileTypes[DocumentType]}${data.File64}`;
          link.setAttribute('download', data.FileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch(({ response }) => {
          this.setNotification(response.data, 'warning');
        });
    },
    downloadCancelation(payroll) {
      this.downLoadFile('FilePDF', payroll.timPayrollEmployeeID, true);
    },
    downloadPDF(payroll) {
      return this.downLoadFile('FilePDF', payroll.timPayrollEmployeeID);
    },
    downloadXML(payroll) {
      return this.downLoadFile('FileXML', payroll.timPayrollEmployeeID);
    },
  },
};
</script>

<style lang="scss">
.filter-bar-stamps {
  .field {
    margin-bottom: 0;
  }
}
.filter-employee-stamps {
  padding-top: 25px;
}
</style>

<template>
  <div>
    <Frame title="Colaboradores">
      <div slot="actions">
        <span class="close-frame" @click="closeDetail">
          <i class="mdi mdi-close"></i>
        </span>
      </div>
      <EmployeeDetail
        v-if="employeeInformation"
        :employeeInformation="employeeInformation"
        @showEditable="(state) => (showModal = state)"
      />
      <TabStrip v-if="employeeInformation">
        <TabStripItem title="Timbrado">
          <EmployeeStamps :employeeInformation="employeeInformation" />
        </TabStripItem>
      </TabStrip>
    </Frame>
    <Modal :active.sync="showModal">
      <EmployeeForm :employee="employeeInformation" />
    </Modal>
  </div>
</template>

<script>
import EmployeeDetail from './EmployeeDetail.vue';
import EmployeeStamps from './EmployeeStamps.vue';
import EmployeeForm from './EmployeeForm.vue';

export default {
  name: 'Employee',
  components: {
    EmployeeDetail,
    EmployeeStamps,
    EmployeeForm,
  },
  data() {
    return {
      employeeInformation: null,
      loading: false,
      showModal: false,
    };
  },
  mounted() {
    this.$root.$on('reloadInfoEmployee', () => {
      this.getEmployee();
    });
    this.getEmployee();
  },
  methods: {
    getEmployee() {
      const { id } = this.$route.params;
      this.getAll(`employee/${id}`, '?query', 'stamp').then((data) => {
        this.employeeInformation = data.employeeInformation;
      });
    },
    closeDetail() {
      if (this.$route.query && this.$route.query.callbackURI) {
        this.$router.push({ name: this.$route.query.callbackURI });
      } else {
        this.$router.push({ name: 'employeesLookup' });
      }
    },
  },
};
</script>

<style lang="scss">
.close-frame {
  cursor: pointer;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Frame',{attrs:{"title":"Colaboradores","loading":_vm.loading}},[_c('Title',{attrs:{"title":"Datos generales","description":"Captura la información personal del colaborador tal como aparece en sus documentos oficiales."}}),_c('Layout',[_c('Column',{attrs:{"size":"4"}},[_c('Field',{attrs:{"label":"Nombre"}},[_c('Input',{attrs:{"required":true,"text-transform":"uppercase","rules":'required'},model:{value:(_vm.employeeInfo.firstName),callback:function ($$v) {_vm.$set(_vm.employeeInfo, "firstName", $$v)},expression:"employeeInfo.firstName"}})],1)],1),_c('Column',{attrs:{"size":"4"}},[_c('Field',{attrs:{"label":"Apellido paterno"}},[_c('Input',{attrs:{"required":true,"text-transform":"uppercase","rules":'required'},model:{value:(_vm.employeeInfo.lastName1),callback:function ($$v) {_vm.$set(_vm.employeeInfo, "lastName1", $$v)},expression:"employeeInfo.lastName1"}})],1)],1),_c('Column',{attrs:{"size":"4"}},[_c('Field',{attrs:{"label":"Apellido materno"}},[_c('Input',{attrs:{"text-transform":"uppercase"},model:{value:(_vm.employeeInfo.lastName2),callback:function ($$v) {_vm.$set(_vm.employeeInfo, "lastName2", $$v)},expression:"employeeInfo.lastName2"}})],1)],1),_c('Column',{attrs:{"size":"4"}},[_c('Field',{attrs:{"label":"RFC"}},[_c('Input',{attrs:{"required":true,"text-transform":"uppercase","rules":{
              required: true,
              regex:
                /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
            }},model:{value:(_vm.employeeInfo.rfc),callback:function ($$v) {_vm.$set(_vm.employeeInfo, "rfc", $$v)},expression:"employeeInfo.rfc"}})],1)],1),_c('Column',{attrs:{"size":"4"}},[_c('Field',{attrs:{"label":"CURP"}},[_c('Input',{attrs:{"required":true,"text-transform":"uppercase","rules":{
              required: true,
              regex:
                /^[A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/,
            }},model:{value:(_vm.employeeInfo.curp),callback:function ($$v) {_vm.$set(_vm.employeeInfo, "curp", $$v)},expression:"employeeInfo.curp"}})],1)],1),_c('Column',{attrs:{"size":"4"}},[_c('Field',{attrs:{"label":"NSS"}},[_c('Input',{attrs:{"required":true,"text-transform":"uppercase","rules":'required'},model:{value:(_vm.employeeInfo.nss),callback:function ($$v) {_vm.$set(_vm.employeeInfo, "nss", $$v)},expression:"employeeInfo.nss"}})],1)],1),_c('Column',{attrs:{"size":"4"}},[_c('Field',{attrs:{"label":"Correo personal"}},[_c('Input',{attrs:{"required":true,"rules":'required|email'},model:{value:(_vm.employeeInfo.personalEmail),callback:function ($$v) {_vm.$set(_vm.employeeInfo, "personalEmail", $$v)},expression:"employeeInfo.personalEmail"}})],1)],1),_c('Column',{attrs:{"size":"4"}},[_c('Field',{attrs:{"label":"Correo empresarial"}},[_c('Input',{attrs:{"rules":'email'},model:{value:(_vm.employeeInfo.enterpriseEmail),callback:function ($$v) {_vm.$set(_vm.employeeInfo, "enterpriseEmail", $$v)},expression:"employeeInfo.enterpriseEmail"}})],1)],1)],1),_c('hr'),_c('Layout',[_c('Column',{attrs:{"align":"right"}},[_c('Button',{attrs:{"color":"terciary-outlined"},on:{"click":_vm.$parent.close}},[_vm._v("Cancelar")]),_c('Button',{attrs:{"color":"secondary"},on:{"click":_vm.saveEmployee}},[_vm._v("Guardar")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }